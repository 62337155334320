.ul-schema, .ul-schema-small {
    list-style: none;
    

    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    flex-wrap: wrap;

    /*height: 800px;*/
    height: auto;
    width: 100%;
}

.ul-schema-small {

}

.ul-entry {
    list-style: none;
    margin: auto; /* Center horizontal */
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 170px;
}

.scheduleitem {
    margin-top: 20px;
    list-style: none;

    flex: 0 0 192px; /* play with this number */
}

.time {
    margin-bottom: 10px;
}

.person {
    margin-top: 20px;
}