.item-login {
    width: 250px;
    height: 50px;
    margin: 20px auto;

    background-color: var(--jss-blue-dark);

    display: flex;

    transition: 0.2s;
}

.item-login:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.google-icon-wrapper {
    width: 50px;
    height: 50px;
    background-color: white;
    box-sizing: border-box; /* Prevent size increase from padding */
    padding: 5px;
}
.google-icon {
    /*
    position: absolute;
    */
    height: 100%;
    width: 100%;
}
.google-text {
    margin: auto;
    margin-left: 16px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2px;
}