:root {
    --background-image: url("https://lh3.googleusercontent.com/fife/AAWUweUL0waHDauN_orVbTZjmNXcPkGOdIwmi2Ot-_kPvaTC_CaStUVcBSczcmdPCGfc1Hkrj75Qh5wpvGIkhziVBlNZlD88Zny7S0qzbosIssRvFDZv871Vyf1GgF7FSCvzqgbv0fRtePhdqQoPOI_r5M5K7c0hAsCn_DPgdWEmCB_gTXfDhfU9hwHOUZdXu8iI9xh9tlrWWg16tLdnv7zGojGEIqpCW8jBKSOWzHRY-hZhdmcaylFsN0dCiu7b6HTv0pr4-xh_0gnMtUykFggIjjPHWJR-8mKmSaPDTwX8LfS4dthU2xh2HcK5NfrevvcZ5GmJFLiLux49dHT7-ffOCHCMn8LnCXLRfUvXTBovhl4GQ56Sm-PLZ54WIV5n-3ltll-suApXZfb95RJvkswDnUZEqftSDeawcr3d59wFZ1tzV5roVYvYm2X91HYwuY2zxkZCq4npSeikxC8DTq2IMk-aevZAoE7a1hL93yKSlETglyC038J-vtNNj-MI6Uo1kVKCSSFhJNIckEoEPO-emhJxslrpxrES74v8UjGsB1McsvNKoqMLF4wbLZUQZX-BGptOAwyvsuVymUDuFOk6Ec5rGu442_tmV7WSkQEHvlPlBZJuYjMsROggoL4G07-VRXqEs2prO_Cy-abqbsAAN0D9i0JLC3qeeIHkERrDzJRetEPHflc1hNMsf7sLG7bJkttGt9L5oyPi1OpHqisEIgzQMw4uhc0Sv1M=w1530-h887-ft");
    --primary: rgb(75, 124, 219);
    --primary-transparent: rgb(75, 124, 219, 95%);
    --primary-dark: rgb(1, 1, 249);

    --highlight: rgb(255, 255, 255);
    --highlight-transparent: rgb(255, 255, 255, 90%);

    --text-primary: rgb(255, 255, 255);
    --text-lightblue: rgb(217,240,253);
    --text-items: rgb(248, 3, 2);
    --text-items-background: rgb(65, 65, 65, 70%);

    --jss-red: rgb(181, 50, 42);
    --jss-blue-light: rgb(75, 124, 219);
    --jss-blue-dark: rgb(45, 28, 144);
    --jss-white: rgb(255, 255, 255);
    --jss-white-transparent: rgb(255, 255, 255, 90%);
    --jss-gray-dark: rgb(65, 65, 65);
    --jss-gray-dark-transparent: rgb(65, 65, 65, 70%);

    --jss-inactive: rgb(112, 112, 112);
    --jss-inactive-text: rgb(99, 99, 99);

    /* Sizes */
    --header-size: 85px;
    --width-margin: 100px;
    --container-text-padding: 0px 150px;
    --container-text-padding-smaller: 0px 50px;
    --container-text-padding-phone: 0px 20px;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./resources/fonts/poppins/Poppins-ExtraLight.otf) format('opentype');
  }
  
  @font-face {
      font-family: 'Reklame';
      src: local('Reklame'), url(./resources/fonts/ReklameScriptMedium.otf) format('opentype');
  }

/* Sizes - small */
@media screen and (max-width: 1200px) {
    :root {
    --header-size: 85px;
    --width-margin: 75px;
    }
} 
/* Sizes - smaller */
@media screen and (max-width: 950px) {
    :root {
    --header-size: 85px;
    --width-margin: 50px;
    }
} 

/* -- Sizes - Pad -- */
@media screen and (max-width: 700px) {
    :root {
        --header-size: 150px;
        --width-margin: 20px;
    }
}

/* Sizes - Mobile */
@media screen and (max-width: 400px) {
    :root {
    --header-size: 120px;
    --width-margin: 20px;
    }
} 


body {
    margin: 0px;
    padding: 0px;

    /* Text Styling */
    color: var(--text-primary);

    /* Backgroud image 
    background-image: var(--background-image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    */
}

.main {
    margin: 0px;
    padding: 0px;
}

/* Stylish font headers */
h1 {
    /* h1 is container headers*/

    margin: 20px;

    font-family: "Reklame";
    font-weight: lighter;
    font-size: 56px;

    letter-spacing: 1px;

    color: var(--text-lightblue);
}

h2 {
    font-family: "Reklame";
    font-weight: lighter;
    color: var(--text-items);
    font-size: 40px;
}

h3 {
    color: var(--primary-dark);
    font-size: 20px;
}
/* Straight font headers */
h4, .time{
    margin: 20px;

    font-family: "Poppins";
    font-size: 30px;
    font-weight: bold;

    line-height: 35px;
}

h5 {
    font-family: "Poppins";
    margin: 0px;
    color: var(--primary);
    font-weight: bold;
    font-size: 20px;
}

p, .person {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

p .important {
    color: var(--jss-red);
}

/* input fonts */
input, textarea {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

textarea {
    resize: vertical;
}

select {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

option {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

ul {
    padding: 0px;
    margin: 0px;
}
/*
.App {
    margin: 0px;
    padding: 0px;
}
*/
