:root {
    --background-image: url("https://lh3.googleusercontent.com/fife/AAWUweUL0waHDauN_orVbTZjmNXcPkGOdIwmi2Ot-_kPvaTC_CaStUVcBSczcmdPCGfc1Hkrj75Qh5wpvGIkhziVBlNZlD88Zny7S0qzbosIssRvFDZv871Vyf1GgF7FSCvzqgbv0fRtePhdqQoPOI_r5M5K7c0hAsCn_DPgdWEmCB_gTXfDhfU9hwHOUZdXu8iI9xh9tlrWWg16tLdnv7zGojGEIqpCW8jBKSOWzHRY-hZhdmcaylFsN0dCiu7b6HTv0pr4-xh_0gnMtUykFggIjjPHWJR-8mKmSaPDTwX8LfS4dthU2xh2HcK5NfrevvcZ5GmJFLiLux49dHT7-ffOCHCMn8LnCXLRfUvXTBovhl4GQ56Sm-PLZ54WIV5n-3ltll-suApXZfb95RJvkswDnUZEqftSDeawcr3d59wFZ1tzV5roVYvYm2X91HYwuY2zxkZCq4npSeikxC8DTq2IMk-aevZAoE7a1hL93yKSlETglyC038J-vtNNj-MI6Uo1kVKCSSFhJNIckEoEPO-emhJxslrpxrES74v8UjGsB1McsvNKoqMLF4wbLZUQZX-BGptOAwyvsuVymUDuFOk6Ec5rGu442_tmV7WSkQEHvlPlBZJuYjMsROggoL4G07-VRXqEs2prO_Cy-abqbsAAN0D9i0JLC3qeeIHkERrDzJRetEPHflc1hNMsf7sLG7bJkttGt9L5oyPi1OpHqisEIgzQMw4uhc0Sv1M=w1530-h887-ft");
    --primary: rgb(75, 124, 219);
    --primary-transparent: rgb(75, 124, 219, 95%);
    --primary-dark: rgb(1, 1, 249);

    --highlight: rgb(255, 255, 255);
    --highlight-transparent: rgb(255, 255, 255, 90%);

    --text-primary: rgb(255, 255, 255);
    --text-lightblue: rgb(217,240,253);
    --text-items: rgb(248, 3, 2);
    --text-items-background: rgb(65, 65, 65, 70%);

    --jss-red: rgb(181, 50, 42);
    --jss-blue-light: rgb(75, 124, 219);
    --jss-blue-dark: rgb(45, 28, 144);
    --jss-white: rgb(255, 255, 255);
    --jss-white-transparent: rgb(255, 255, 255, 90%);
    --jss-gray-dark: rgb(65, 65, 65);
    --jss-gray-dark-transparent: rgb(65, 65, 65, 70%);

    --jss-inactive: rgb(112, 112, 112);
    --jss-inactive-text: rgb(99, 99, 99);

    /* Sizes */
    --header-size: 85px;
    --width-margin: 100px;
    --container-text-padding: 0px 150px;
    --container-text-padding-smaller: 0px 50px;
    --container-text-padding-phone: 0px 20px;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(/static/media/Poppins-ExtraLight.4918ffef.otf) format('opentype');
  }
  
  @font-face {
      font-family: 'Reklame';
      src: local('Reklame'), url(/static/media/ReklameScriptMedium.3ca3f54c.otf) format('opentype');
  }

/* Sizes - small */
@media screen and (max-width: 1200px) {
    :root {
    --header-size: 85px;
    --width-margin: 75px;
    }
} 
/* Sizes - smaller */
@media screen and (max-width: 950px) {
    :root {
    --header-size: 85px;
    --width-margin: 50px;
    }
} 

/* -- Sizes - Pad -- */
@media screen and (max-width: 700px) {
    :root {
        --header-size: 150px;
        --width-margin: 20px;
    }
}

/* Sizes - Mobile */
@media screen and (max-width: 400px) {
    :root {
    --header-size: 120px;
    --width-margin: 20px;
    }
} 


body {
    margin: 0px;
    padding: 0px;

    /* Text Styling */
    color: rgb(255, 255, 255);
    color: var(--text-primary);

    /* Backgroud image 
    background-image: var(--background-image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    */
}

.main {
    margin: 0px;
    padding: 0px;
}

/* Stylish font headers */
h1 {
    /* h1 is container headers*/

    margin: 20px;

    font-family: "Reklame";
    font-weight: lighter;
    font-size: 56px;

    letter-spacing: 1px;

    color: rgb(217,240,253);

    color: var(--text-lightblue);
}

h2 {
    font-family: "Reklame";
    font-weight: lighter;
    color: rgb(248, 3, 2);
    color: var(--text-items);
    font-size: 40px;
}

h3 {
    color: rgb(1, 1, 249);
    color: var(--primary-dark);
    font-size: 20px;
}
/* Straight font headers */
h4, .time{
    margin: 20px;

    font-family: "Poppins";
    font-size: 30px;
    font-weight: bold;

    line-height: 35px;
}

h5 {
    font-family: "Poppins";
    margin: 0px;
    color: rgb(75, 124, 219);
    color: var(--primary);
    font-weight: bold;
    font-size: 20px;
}

p, .person {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

p .important {
    color: rgb(181, 50, 42);
    color: var(--jss-red);
}

/* input fonts */
input, textarea {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

textarea {
    resize: vertical;
}

select {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

option {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: bold;
}

ul {
    padding: 0px;
    margin: 0px;
}
/*
.App {
    margin: 0px;
    padding: 0px;
}
*/

.main {
    position: absolute;

    top: var(--header-size); 
    bottom: 0px;
    left: 0px;
    right: 0px;

    /* -- Child positioning -- */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    padding: 0px var(--width-margin);
}

#page {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.main-event {
    
}

#loading {

    margin-top: 100px;
    margin-bottom: 100px;

}
.container {
    border-radius: 30px;
    border: 4px solid var(--highlight);

    background-color: var(--primary-transparent);

    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;

    padding-bottom: 25px;
}

.small {
    margin: 0px 15%;
    margin-bottom: 50px;
}

.divider {
    border-top: 4px solid var(--highlight);
    width: 50%;
    margin: auto;
}

/* Special colors for background/text/divider in news */
.news {
    background-color: var(--highlight-transparent);
    border: 4px solid var(--primary);
    color: var(--primary);
}

/*
.news p {
    font-size: 14px;
}
*/

.news h1 {
    color: var(--primary);
}

.news .divider {
    border-top: 4px solid var(--primary);
}

/* Special colors for background/text/divider in admin-containers */
.admin {
    background-color: var(--highlight-transparent);
    border: 4px solid var(--jss-red);
    color: var(--jss-red);
}

.admin h1 {
    color: var(--jss-red);
}

.admin .divider {
    border-top: 4px solid var(--jss-red);
}

.items {
    list-style: none;
}
:root {
    --short-size: 250px;
    --long-size: 200px;
    --item-long-width: 450px;

    --item-long-height-mobile: 450px;
    --item-long-width-mobile: 250px;
}

.item-text {
    margin: var(--container-text-padding);
    line-height: 40px;
}

.image-cover {
    margin: 20px 20px;
}

.image-cover img{
    max-width: 100%;
    height: auto;
    border: 4px solid var(--primary);
}

.image-half{
    display: inline-block;
    width: 45%;
    margin: 0px 10px;
}
.image-half img{
    max-width: 100%;
    height: auto;
    border: 4px solid var(--primary);
}

.li-item-short {
    height: 250px;
    height: var(--short-size);
    width: 250px;
    width: var(--short-size);
    margin: 30px 30px;

    border: 4px solid var(--highlight);
    border-radius: 5px;

    background-color: var(--jss-gray-dark-transparent);

    display: inline-block; /* Center list items */
    text-align: center;

    transition: 0.3s;

    z-index: 3;
}

/* https://www.w3schools.com/howto/howto_css_flip_card.asp */

.item-short {
    position: relative;
    text-align: center;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}



.item-short img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.li-item-short:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

@media (hover: hover) {
    .li-item-short:hover {
        cursor: pointer;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    .li-item-short:hover .item-short img{
        opacity: 0.4;
        filter: alpha(opacity=40);
        z-index: 0;
    }
    
    .li-item-long:hover {
        cursor: pointer;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

@media (hover: none) {

}

.item-short h2 {
    position: absolute;
    top: 35%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    /* background */
    background-color: var(--text-items-background);
    padding: 7px 0px;
    width: 90%;
    z-index: 2;

    transition: 0.3s;
}

/* -- Long items (news) */

.li-item-long {
    height: 200px;
    height: var(--long-size);
    width: 450px;
    width: var(--item-long-width);
    margin: 30px 10px;

    border: 4px solid var(--primary);
    border-radius: 5px;

    transition: 0.2s;

    display: inline-block; /* Center list items */ 
}

.item-long {
    position: relative;
}

.item-long img{
    height: 200px;
    height: var(--long-size);
    width: 200px;
    width: var(--long-size);
    float: left;
    object-fit: cover;
    border-right: 4px solid var(--primary);
}

.item-long h5{
    
}

/*
.content {
    width: 100%;
    text-align: left;
    padding-left: var(--long-size);
}
*/

.item-long p{
    color: var(--primary);

    font-size: 14px;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* -- Smaller browser -- */
@media screen and (max-width: 950px) {
    .item-text {
        margin: var(--container-text-padding-smaller);
        line-height: 30px;
    }
}

/* -- Pad size -- */
@media screen and (max-width: 700px) {
    /* -- Long items (news) */

.li-item-long {
    height: 450px;
    height: var(--item-long-height-mobile);
    width: 250px;
    width: var(--item-long-width-mobile);
    margin: 30px 50px;
}

.item-long img{
    height: 250px;
    height: var(--item-long-width-mobile);
    width: 250px;
    width: var(--item-long-width-mobile);

    float:inline-start ;

    margin-bottom: 10px;
}


.item-long p{
    margin: 10px 5px;
}
}

/* -- Phones -- */
@media screen and (max-width: 420px) {
    .item-text {
        margin: var(--container-text-padding-phone);
        line-height: 25px;
    }

/* -- Long items (news) */

.li-item-long {
    height: 450px;
    height: var(--item-long-height-mobile);
    width: 250px;
    width: var(--item-long-width-mobile);
    margin: 30px 10px;
}

.item-long img{
    height: 250px;
    height: var(--item-long-width-mobile);
    width: 250px;
    width: var(--item-long-width-mobile);

    float:inline-start ;

    margin-bottom: 10px;
}


.item-long p{
    margin: 10px 5px;
}
}
:root {
    --Left: 25%;
    --Right: 75%;
    --Top: 50%;
    --Bot: 50%;

    --NW: var(--Left) 0;
    --NE: var(--Right) 0;

    --EN: 100% var(--Top);
    --ES: 100% var(--Bot);

    --SE: var(--Right) 100%;
    --SW: var(--Left) 100%;

    --WS: 0 var(--Bot);
    --WN: 0 var(--Top);
}

.header {

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;

    position:absolute;
    
    top: 0px; 
    bottom: auto;
    right: 0px;
    left: 0px;

    width: auto;
    height: var(--header-size);

    overflow: hidden;
    padding: 10px var(--width-margin);

    /*
    display: flex;
    flex-direction: column;
    */

    box-sizing: border-box; /*Prevents resize from padding*/

    background-color: var(--primary);
}

.header-items-container {
    height: 100%;
    display: flex; 
    justify-content: space-between;
    align-items: center;
}

.header-items-container-one{
    justify-content: center;
}

.header-item, .header-item-large, .header-border-item-large, .header-border-item{
    width: 160px;
    height: 40px;

    background-color: var(--jss-white);
    color: var(--jss-blue-dark);

    /* -- Text -- */
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    font-family: "Reklame";
    font-weight: lighter;
    letter-spacing: 2px;

    transition: 0.2s;

    -webkit-clip-path: polygon(
    25% 0, 75% 0, 100% 50%, 100% 50%, 75% 100%, 25% 100%, 0 50%, 0 50%);

            clip-path: polygon(
    25% 0, 75% 0, 100% 50%, 100% 50%, 75% 100%, 25% 100%, 0 50%, 0 50%);

    -webkit-clip-path: polygon(
    var(--NW), var(--NE), var(--EN), var(--ES), var(--SE), var(--SW), var(--WS), var(--WN)
    );

            clip-path: polygon(
    var(--NW), var(--NE), var(--EN), var(--ES), var(--SE), var(--SW), var(--WS), var(--WN)
    )
}

.header-border-item {
    display: flex; 
    justify-content: center;
    align-items: center;

    width: 180px;
    height: 50px;
    background-color: var(--jss-red);
}

.header-item-large {
    width: 240px;
    height: 55px;
    line-height: 55px;
    font-size: 40px;

    background-color: var(--jss-blue-dark);
    color: var(--jss-white);
}

.header-border-item-large {
    display: flex; 
    justify-content: center;
    align-items: center;

    width: 260px;
    height: 65px;
    background-color: var(--jss-red);
}




@media (hover: hover) {
    .header-border-item:hover, .header-border-item-large:hover {
        cursor: pointer;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        
        cursor: pointer;
        /*
            zoom : 110%;
        */
    }
}
.header-border-item:active, .header-border-item-large:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

/* -- Responsive CSS */

/* -- Small browser -- */
@media screen and (max-width: 1200px) {
    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 130px;
        height: 40px;
    
        /* -- Text -- */
        font-size: 20px;
        line-height: 40px;
    }
    
    .header-border-item {
        width: 150px;
        height: 50px;
    }
    
    .header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
    
    .header-border-item-large {
        width: 178px;
        height: 65px;
    }
}

/* -- Smaller browser -- */
@media screen and (max-width: 950px) {
    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 100px;
        height: 30px;
    
        /* -- Text -- */
        font-size: 16px;
        line-height: 30px;
    }
    
    .header-border-item {
        width: 110px;
        height: 35px;
    }
    
    .header-item-large {
        width: 140px;
        height: 40px;
        line-height: 40px;
        font-size: 22px;
    }
    
    .header-border-item-large {
        width: 155px;
        height: 47px;
    }
}

/* -- Pad size -- */
@media screen and (max-width: 700px) {
    .header-items-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 110px;
        height: 22px;

        margin: 2px 10px;
        
        /* -- Text -- */
        font-size: 10px;
        line-height: 20px;

        -webkit-clip-path: none;

                clip-path: none;

        border-radius: 20px;
    }

    .header-item {
        margin: 0px;
    }
    
    .header-border-item {
        width: 120px;
        height: 30px;
    }
    
    .header-item-large {
        margin: 0px;
        width: 99%;
        height: 90%;
        line-height: 50px;
        font-size: 18px;
    }
    
    .header-border-item-large {
        width: 80%;
        height: 50px;
        order: 1;
    }
}

/* -- Phones -- */
@media screen and (max-width: 420px) {
    .header-items-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 110px;
        height: 22px;

        margin: 2px 10px;
        
        /* -- Text -- */
        font-size: 10px;
        line-height: 20px;

        -webkit-clip-path: none;

                clip-path: none;

        border-radius: 20px;
    }

    .header-item {
        margin: 0px;
    }
    
    .header-border-item {
        width: 120px;
        height: 30px;
    }
    
    .header-item-large {
        margin: 0px;
        width: 225px;
        height: 25px;
        line-height: 25px;
        font-size: 18px;
    }
    
    .header-border-item-large {
        width: 235px;
        height: 30px;
        order: 1;
    }
}
.ul-schema, .ul-schema-small {
    list-style: none;
    

    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    flex-wrap: wrap;

    /*height: 800px;*/
    height: auto;
    width: 100%;
}

.ul-schema-small {

}

.ul-entry {
    list-style: none;
    margin: auto; /* Center horizontal */
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 170px;
}

.scheduleitem {
    margin-top: 20px;
    list-style: none;

    flex: 0 0 192px; /* play with this number */
}

.time {
    margin-bottom: 10px;
}

.person {
    margin-top: 20px;
}
.item-login {
    width: 250px;
    height: 50px;
    margin: 20px auto;

    background-color: var(--jss-blue-dark);

    display: flex;

    transition: 0.2s;
}

.item-login:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.google-icon-wrapper {
    width: 50px;
    height: 50px;
    background-color: white;
    box-sizing: border-box; /* Prevent size increase from padding */
    padding: 5px;
}
.google-icon {
    /*
    position: absolute;
    */
    height: 100%;
    width: 100%;
}
.google-text {
    margin: auto;
    margin-left: 16px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2px;
}
:root {
    --admin-short-size: 200px;
}

.admin-items {
    display: flex;
    flex-direction: column;
}

.admin-items-row {
    display: flex;
}

.admin-items-header {
    margin: 5px 0px;
}

.li-admin-item-short {
    height: 200px;
    height: var(--admin-short-size);
    width: 200px;
    width: var(--admin-short-size);
    margin: 20px auto;
    margin-top: 0px;

    border: 4px solid var(--jss-red);
    border-radius: 5px;

    background-color: var(--jss-blue-light);

    display: flex;

    transition: 0.3s;
}

.inactive {
    background-color: var(--jss-inactive);
    color: var(--jss-inactive-text);
    border-color: black;
}

@media (hover: hover) {
    .li-admin-item-short:hover {
        cursor: pointer;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    .inactive:hover {
        cursor: default;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.admin-item-short {
    height: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.admin-header {
    background-color: var(--jss-red);
}

.admin-header-items-container {
    
}

.admin-header-item, .admin-header-item-large{
    width: 150px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;

    font-weight: bold;
    text-align: center;
    line-height: 40px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    transition: 0.2s;

    color: var(--jss-blue-dark);
    background-color: var(--jss-white);
    border: 4px solid var(--jss-blue-dark);
    border-radius: 25px;
}

.admin-header-item-large {
    width: 170px;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
}
@media (hover: hover) {
    .admin-header-item:hover, .admin-header-item-large:hover{
        cursor: pointer;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}

/* -- Small browser -- */
@media screen and (max-width: 1200px) {
    .admin-header-item{
        width: 130px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }

    .admin-header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
}

/* -- Smaller browser -- */
@media screen and (max-width: 950px) {
    .admin-header-item{
        width: 130px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }

    .admin-header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
}

/* -- Phones -- */
@media screen and (max-width: 420px) {
    .admin-header-item{
        width: 130px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }

    .admin-header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
}
#add {
background-color: var(--jss-white);
border-radius: 50%;
border: 4px solid var(--jss-red);

text-align: center;

height: 25px;
width: 25px;
line-height: 27px;
font-size: 25px;
font-weight: bold;
}

#add-text {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
}

.new-items-list {
    list-style: none;
    display: flex;
    flex-direction: column;
}

.input-new-item {

}

.input-new-container, .input-new-item, .input-new-post {
    /*text-align: center;*/

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    transition: 0.2s;
}

.input-new-post {
    
    width: 50%;
    margin: 20px auto 0px auto;
}

.input-new-item {
    margin: auto;

    margin-top: 20px; /* -- This might need to change -- */

    background-color: var(--jss-white);

    width: 50%;
    border-radius: 20px;
    border: 4px solid var(--jss-red);

    transition: 0.2s;
}

@media (hover: hover) {
    .input-new-container:hover, .input-new-item:hover, .input-new-post:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        cursor: pointer;
    }
}

/* Items in Container */

.input-container-title {
    margin: 10px auto;
    text-align: center;
    max-width: 80%;

    font-size: 40px;

    height: auto;
    background-color: var(--jss-white);
    border: 4px solid var(--jss-red);
    border-radius: 25px;
}

.item-type {
    margin: 10px auto;

    height: 50px;
    width: 60%;

    background-color: var(--jss-white);
    border: 4px solid var(--jss-red);
    border-radius: 25px;

    padding-left: 20px;
}

.item-type option{
    
}

.new-item {
    margin: 10px auto;
    padding: 25px 0px;

    min-height: 100px;
    height: auto;
    width: 80%;

    background-color: var(--jss-white);
    border: 4px solid var(--jss-red);
    border-radius: 25px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.type-text {

}

.input-text-caption {
    width: 80%;
    height: 40px;
    font-size: 30px;
}

.input-text-content {
    margin-top: 20px;
    width: 80%;
}

.type-image-cover {
    padding-top: 0px;
}

.type-image-half {
    padding-top: 0px;
}
.service-add-news {

}
