.container {
    border-radius: 30px;
    border: 4px solid var(--highlight);

    background-color: var(--primary-transparent);

    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 30px;

    padding-bottom: 25px;
}

.small {
    margin: 0px 15%;
    margin-bottom: 50px;
}

.divider {
    border-top: 4px solid var(--highlight);
    width: 50%;
    margin: auto;
}

/* Special colors for background/text/divider in news */
.news {
    background-color: var(--highlight-transparent);
    border: 4px solid var(--primary);
    color: var(--primary);
}

/*
.news p {
    font-size: 14px;
}
*/

.news h1 {
    color: var(--primary);
}

.news .divider {
    border-top: 4px solid var(--primary);
}

/* Special colors for background/text/divider in admin-containers */
.admin {
    background-color: var(--highlight-transparent);
    border: 4px solid var(--jss-red);
    color: var(--jss-red);
}

.admin h1 {
    color: var(--jss-red);
}

.admin .divider {
    border-top: 4px solid var(--jss-red);
}

.items {
    list-style: none;
}