.admin-header {
    background-color: var(--jss-red);
}

.admin-header-items-container {
    
}

.admin-header-item, .admin-header-item-large{
    width: 150px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;

    font-weight: bold;
    text-align: center;
    line-height: 40px;
    user-select: none;

    transition: 0.2s;

    color: var(--jss-blue-dark);
    background-color: var(--jss-white);
    border: 4px solid var(--jss-blue-dark);
    border-radius: 25px;
}

.admin-header-item-large {
    width: 170px;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
}
@media (hover: hover) {
    .admin-header-item:hover, .admin-header-item-large:hover{
        cursor: pointer;
        transform: scale(1.1);
    }
}

/* -- Small browser -- */
@media screen and (max-width: 1200px) {
    .admin-header-item{
        width: 130px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }

    .admin-header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
}

/* -- Smaller browser -- */
@media screen and (max-width: 950px) {
    .admin-header-item{
        width: 130px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }

    .admin-header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
}

/* -- Phones -- */
@media screen and (max-width: 420px) {
    .admin-header-item{
        width: 130px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }

    .admin-header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
}