.main {
    position: absolute;

    top: var(--header-size); 
    bottom: 0px;
    left: 0px;
    right: 0px;

    /* -- Child positioning -- */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    padding: 0px var(--width-margin);
}

#page {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.main-event {
    
}

#loading {

    margin-top: 100px;
    margin-bottom: 100px;

}