#add {
background-color: var(--jss-white);
border-radius: 50%;
border: 4px solid var(--jss-red);

text-align: center;

height: 25px;
width: 25px;
line-height: 27px;
font-size: 25px;
font-weight: bold;
}

#add-text {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
}

.new-items-list {
    list-style: none;
    display: flex;
    flex-direction: column;
}

.input-new-item {

}

.input-new-container, .input-new-item, .input-new-post {
    /*text-align: center;*/

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    transition: 0.2s;
}

.input-new-post {
    
    width: 50%;
    margin: 20px auto 0px auto;
}

.input-new-item {
    margin: auto;

    margin-top: 20px; /* -- This might need to change -- */

    background-color: var(--jss-white);

    width: 50%;
    border-radius: 20px;
    border: 4px solid var(--jss-red);

    transition: 0.2s;
}

@media (hover: hover) {
    .input-new-container:hover, .input-new-item:hover, .input-new-post:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
}

/* Items in Container */

.input-container-title {
    margin: 10px auto;
    text-align: center;
    max-width: 80%;

    font-size: 40px;

    height: auto;
    background-color: var(--jss-white);
    border: 4px solid var(--jss-red);
    border-radius: 25px;
}

.item-type {
    margin: 10px auto;

    height: 50px;
    width: 60%;

    background-color: var(--jss-white);
    border: 4px solid var(--jss-red);
    border-radius: 25px;

    padding-left: 20px;
}

.item-type option{
    
}

.new-item {
    margin: 10px auto;
    padding: 25px 0px;

    min-height: 100px;
    height: auto;
    width: 80%;

    background-color: var(--jss-white);
    border: 4px solid var(--jss-red);
    border-radius: 25px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.type-text {

}

.input-text-caption {
    width: 80%;
    height: 40px;
    font-size: 30px;
}

.input-text-content {
    margin-top: 20px;
    width: 80%;
}

.type-image-cover {
    padding-top: 0px;
}

.type-image-half {
    padding-top: 0px;
}