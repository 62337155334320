:root {
    --short-size: 250px;
    --long-size: 200px;
    --item-long-width: 450px;

    --item-long-height-mobile: 450px;
    --item-long-width-mobile: 250px;
}

.item-text {
    margin: var(--container-text-padding);
    line-height: 40px;
}

.image-cover {
    margin: 20px 20px;
}

.image-cover img{
    max-width: 100%;
    height: auto;
    border: 4px solid var(--primary);
}

.image-half{
    display: inline-block;
    width: 45%;
    margin: 0px 10px;
}
.image-half img{
    max-width: 100%;
    height: auto;
    border: 4px solid var(--primary);
}

.li-item-short {
    height: var(--short-size);
    width: var(--short-size);
    margin: 30px 30px;

    border: 4px solid var(--highlight);
    border-radius: 5px;

    background-color: var(--jss-gray-dark-transparent);

    display: inline-block; /* Center list items */
    text-align: center;

    transition: 0.3s;

    z-index: 3;
}

/* https://www.w3schools.com/howto/howto_css_flip_card.asp */

.item-short {
    position: relative;
    text-align: center;

    user-select: none;
}



.item-short img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.li-item-short:active {
    transform: scale(0.9);
}

@media (hover: hover) {
    .li-item-short:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    .li-item-short:hover .item-short img{
        opacity: 0.4;
        filter: alpha(opacity=40);
        z-index: 0;
    }
    
    .li-item-long:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
}

@media (hover: none) {

}

.item-short h2 {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* background */
    background-color: var(--text-items-background);
    padding: 7px 0px;
    width: 90%;
    z-index: 2;

    transition: 0.3s;
}

/* -- Long items (news) */

.li-item-long {
    height: var(--long-size);
    width: var(--item-long-width);
    margin: 30px 10px;

    border: 4px solid var(--primary);
    border-radius: 5px;

    transition: 0.2s;

    display: inline-block; /* Center list items */ 
}

.item-long {
    position: relative;
}

.item-long img{
    height: var(--long-size);
    width: var(--long-size);
    float: left;
    object-fit: cover;
    border-right: 4px solid var(--primary);
}

.item-long h5{
    
}

/*
.content {
    width: 100%;
    text-align: left;
    padding-left: var(--long-size);
}
*/

.item-long p{
    color: var(--primary);

    font-size: 14px;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* -- Smaller browser -- */
@media screen and (max-width: 950px) {
    .item-text {
        margin: var(--container-text-padding-smaller);
        line-height: 30px;
    }
}

/* -- Pad size -- */
@media screen and (max-width: 700px) {
    /* -- Long items (news) */

.li-item-long {
    height: var(--item-long-height-mobile);
    width: var(--item-long-width-mobile);
    margin: 30px 50px;
}

.item-long img{
    height: var(--item-long-width-mobile);
    width: var(--item-long-width-mobile);

    float:inline-start ;

    margin-bottom: 10px;
}


.item-long p{
    margin: 10px 5px;
}
}

/* -- Phones -- */
@media screen and (max-width: 420px) {
    .item-text {
        margin: var(--container-text-padding-phone);
        line-height: 25px;
    }

/* -- Long items (news) */

.li-item-long {
    height: var(--item-long-height-mobile);
    width: var(--item-long-width-mobile);
    margin: 30px 10px;
}

.item-long img{
    height: var(--item-long-width-mobile);
    width: var(--item-long-width-mobile);

    float:inline-start ;

    margin-bottom: 10px;
}


.item-long p{
    margin: 10px 5px;
}
}