:root {
    --admin-short-size: 200px;
}

.admin-items {
    display: flex;
    flex-direction: column;
}

.admin-items-row {
    display: flex;
}

.admin-items-header {
    margin: 5px 0px;
}

.li-admin-item-short {
    height: var(--admin-short-size);
    width: var(--admin-short-size);
    margin: 20px auto;
    margin-top: 0px;

    border: 4px solid var(--jss-red);
    border-radius: 5px;

    background-color: var(--jss-blue-light);

    display: flex;

    transition: 0.3s;
}

.inactive {
    background-color: var(--jss-inactive);
    color: var(--jss-inactive-text);
    border-color: black;
}

@media (hover: hover) {
    .li-admin-item-short:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
    .inactive:hover {
        cursor: default;
        transform: scale(1);
    }
}

.admin-item-short {
    height: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto;
    user-select: none;
}