:root {
    --Left: 25%;
    --Right: 75%;
    --Top: 50%;
    --Bot: 50%;

    --NW: var(--Left) 0;
    --NE: var(--Right) 0;

    --EN: 100% var(--Top);
    --ES: 100% var(--Bot);

    --SE: var(--Right) 100%;
    --SW: var(--Left) 100%;

    --WS: 0 var(--Bot);
    --WN: 0 var(--Top);
}

.header {

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;

    position:absolute;
    
    top: 0px; 
    bottom: auto;
    right: 0px;
    left: 0px;

    width: auto;
    height: var(--header-size);

    overflow: hidden;
    padding: 10px var(--width-margin);

    /*
    display: flex;
    flex-direction: column;
    */

    box-sizing: border-box; /*Prevents resize from padding*/

    background-color: var(--primary);
}

.header-items-container {
    height: 100%;
    display: flex; 
    justify-content: space-between;
    align-items: center;
}

.header-items-container-one{
    justify-content: center;
}

.header-item, .header-item-large, .header-border-item-large, .header-border-item{
    width: 160px;
    height: 40px;

    background-color: var(--jss-white);
    color: var(--jss-blue-dark);

    /* -- Text -- */
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    font-family: "Reklame";
    font-weight: lighter;
    letter-spacing: 2px;

    transition: 0.2s;

    -webkit-clip-path: polygon(
    25% 0, 75% 0, 100% 50%, 100% 50%, 75% 100%, 25% 100%, 0 50%, 0 50%);

            clip-path: polygon(
    25% 0, 75% 0, 100% 50%, 100% 50%, 75% 100%, 25% 100%, 0 50%, 0 50%);

    -webkit-clip-path: polygon(
    var(--NW), var(--NE), var(--EN), var(--ES), var(--SE), var(--SW), var(--WS), var(--WN)
    );

            clip-path: polygon(
    var(--NW), var(--NE), var(--EN), var(--ES), var(--SE), var(--SW), var(--WS), var(--WN)
    )
}

.header-border-item {
    display: flex; 
    justify-content: center;
    align-items: center;

    width: 180px;
    height: 50px;
    background-color: var(--jss-red);
}

.header-item-large {
    width: 240px;
    height: 55px;
    line-height: 55px;
    font-size: 40px;

    background-color: var(--jss-blue-dark);
    color: var(--jss-white);
}

.header-border-item-large {
    display: flex; 
    justify-content: center;
    align-items: center;

    width: 260px;
    height: 65px;
    background-color: var(--jss-red);
}




@media (hover: hover) {
    .header-border-item:hover, .header-border-item-large:hover {
        cursor: pointer;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        
        cursor: pointer;
        /*
            zoom : 110%;
        */
    }
}
.header-border-item:active, .header-border-item-large:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

/* -- Responsive CSS */

/* -- Small browser -- */
@media screen and (max-width: 1200px) {
    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 130px;
        height: 40px;
    
        /* -- Text -- */
        font-size: 20px;
        line-height: 40px;
    }
    
    .header-border-item {
        width: 150px;
        height: 50px;
    }
    
    .header-item-large {
        width: 160px;
        height: 55px;
        line-height: 55px;
        font-size: 28px;
    }
    
    .header-border-item-large {
        width: 178px;
        height: 65px;
    }
}

/* -- Smaller browser -- */
@media screen and (max-width: 950px) {
    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 100px;
        height: 30px;
    
        /* -- Text -- */
        font-size: 16px;
        line-height: 30px;
    }
    
    .header-border-item {
        width: 110px;
        height: 35px;
    }
    
    .header-item-large {
        width: 140px;
        height: 40px;
        line-height: 40px;
        font-size: 22px;
    }
    
    .header-border-item-large {
        width: 155px;
        height: 47px;
    }
}

/* -- Pad size -- */
@media screen and (max-width: 700px) {
    .header-items-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 110px;
        height: 22px;

        margin: 2px 10px;
        
        /* -- Text -- */
        font-size: 10px;
        line-height: 20px;

        -webkit-clip-path: none;

                clip-path: none;

        border-radius: 20px;
    }

    .header-item {
        margin: 0px;
    }
    
    .header-border-item {
        width: 120px;
        height: 30px;
    }
    
    .header-item-large {
        margin: 0px;
        width: 99%;
        height: 90%;
        line-height: 50px;
        font-size: 18px;
    }
    
    .header-border-item-large {
        width: 80%;
        height: 50px;
        order: 1;
    }
}

/* -- Phones -- */
@media screen and (max-width: 420px) {
    .header-items-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .header-item, .header-item-large, .header-border-item-large, .header-border-item{
        width: 110px;
        height: 22px;

        margin: 2px 10px;
        
        /* -- Text -- */
        font-size: 10px;
        line-height: 20px;

        -webkit-clip-path: none;

                clip-path: none;

        border-radius: 20px;
    }

    .header-item {
        margin: 0px;
    }
    
    .header-border-item {
        width: 120px;
        height: 30px;
    }
    
    .header-item-large {
        margin: 0px;
        width: 225px;
        height: 25px;
        line-height: 25px;
        font-size: 18px;
    }
    
    .header-border-item-large {
        width: 235px;
        height: 30px;
        order: 1;
    }
}